import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {PlatformService} from '../services/platform.service';
import {Router} from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    public authenticationService: AuthenticationService,
    public platformService: PlatformService,
    public router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    if (request.url.indexOf('poc-backend-sogaris-services.azurewebsites.net') >= 0
      || request.url.indexOf('lpu-backend-preprod.azurewebsites.net') >= 0
      || request.url.indexOf('lpu-backend-prod.azurewebsites.net') >= 0
      || request.url.indexOf('localhost') >= 0
      || request.url.indexOf('185.64.149.141') >= 0
      || request.url.indexOf('127.0.0.1') >= 0) {
      const currentToken = this.platformService.localStorageGet('currentToken');
      if (currentToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentToken}`
          }
        });
      }
    }
    return next.handle(request);
  }
}
