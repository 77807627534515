import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';
import * as moment from 'moment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public apiEndpoint = environment.apiEndPoint;

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getQueryString(queryParams: any): string {
    if (!queryParams) {
      return null;
    }

    const result = queryParams ? '?' : '';
    const queryStrings = [];
    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (!Array.isArray(value)) {
          queryStrings.push(key + '=' + value);
        } else {
          for (const v of value) {
            queryStrings.push(key + '[]=' + v);
          }
        }

      }
    }
    return result + queryStrings.join('&');
  }

  public isDev(): boolean {
    return environment.apiEndPoint.indexOf('poc-backend-sogaris-services.azurewebsites.net') >= 0;
  }

  public extractData(res: any): any {
    const body = res ? {
      message: res.hasOwnProperty('message') ? res.message : null,
      data: res.hasOwnProperty('data') ? res.data : null,
    } : res;
    return body || {};
  }

  public formatJson = obj => {
    if (Array.isArray(obj)) {
      return obj
        .map(v => (v && typeof v === 'object') ? this.formatJson(v) : v)
        .filter(v => !(v == null));
    }
    return Object.entries(obj)
      .map(([k, v]) => [k, v && moment.isMoment(v) ? v.format('YYYY-MM-DDT00:00:00') : v])
      .map(([k, v]) => [k, v && typeof v === 'object' ? this.formatJson(v) : v])
      .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {});

  }

  public handleError<T>(operation = 'operation', result?: T): any {
    return () => of(result as T);
  }

  get(path: string, queryParams?: any, queryString: string = null): Observable<any> {
    return this.httpClient.get(this.apiEndpoint + encodeURI(path) + (this.getQueryString(queryParams) || queryString || ''))
      .pipe(map(this.extractData));
  }

  post(path: string, object: any): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + encodeURI(path), JSON.stringify(this.formatJson(object)), httpOptions);
  }

  put(path: string, object: any): Observable<any> {
    return this.httpClient.put<any>(this.apiEndpoint + encodeURI(path), JSON.stringify(this.formatJson(object)), httpOptions);
  }

  delete(path: string): Observable<any> {
    return this.httpClient.delete(this.apiEndpoint + encodeURI(path), httpOptions);
  }
}
