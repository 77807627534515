import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer.component';
import {AppRoutingModule} from "../../../app-routing.module";



@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
    imports: [
        CommonModule,
        AppRoutingModule
    ]
})
export class FooterModule { }
