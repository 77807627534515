import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './core/services/authentication.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ScrollService} from './core/services/scroll.service';
import {HeaderService} from './core/services/header.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'poc-frontend-sogaris-service';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private scrollService: ScrollService,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
  ) {
    this.router.events
      .pipe(untilDestroyed(this))
      .subscribe(evt => {
        if (evt instanceof NavigationEnd) {
          this.headerService.showMenu.next(false);
          this.activatedRoute.fragment.subscribe(fragment => {
            if (!fragment) {
              this.scrollService.toTop(0);
            }
          });
        }
      });
  }

  ngOnInit(): void {
    this.authenticationService.autoLogin();
  }
}
