import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {NavigationEnd, Router} from '@angular/router';
import {ScrollService} from '../../../core/services/scroll.service';
import {HeaderService} from '../../../core/services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public currentRoute: string | undefined;
  public showMenu = false;

  constructor(
    public authentictionService: AuthenticationService,
    private router: Router,
    public headerService: HeaderService,
  ) {
  }

  ngOnInit(): void {
    this.headerService.showMenu.subscribe(showMenu => this.showMenu = showMenu);
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.currentRoute = evt.url;
      }
    });
  }
}
