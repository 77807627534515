import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public serverErrors = new BehaviorSubject<string[]>([]);
  public clientErrors = new BehaviorSubject<string[]>([]);

  constructor() {
  }

  getFormValidationErrors(formElement: any, withReset = true, key: string = null): void {
    if (environment.production) {
      return;
    }

    if (withReset) {
      this.clientErrors.next([]);
    }

    const clientErrors = this.clientErrors.value || [];

    if (formElement instanceof UntypedFormControl) {
      const controlErrors: ValidationErrors = formElement.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          clientErrors.push(`control: ${key} , error: ${keyError}, value: ${controlErrors[keyError]}`);
        });
      }
    }

    if (formElement instanceof UntypedFormGroup) {
      Object.keys(formElement.controls).forEach(k => {
        this.getFormValidationErrors(formElement.controls[k], false, k);
      });
    }

    if (formElement instanceof UntypedFormArray) {
      formElement.controls.forEach(control => {
        this.getFormValidationErrors(control, false);
      });
    }

    this.clientErrors.next(clientErrors);
  }


}
