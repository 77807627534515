import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoadingService} from '../services/loading.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    public loadingService: LoadingService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          this.loadingService.isLoading.next(false);
          this.loadingService.currentLoading.next(null);
        }
      })
    );
  }
}
