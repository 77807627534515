import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {PlatformService} from '../services/platform.service';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public platformService: PlatformService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.platformService.localStorageGet('currentToken')) {
      this.authenticationService.lastRoute.next(null);
      return true;
    }

    this.authenticationService.lastRoute.next(route);

    this.router.navigate(['']).then();
    return false;
  }
}
