import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {ErrorService} from '../services/error.service';
import {LoadingService} from '../services/loading.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          let errorMessage: string;
          const errors: string[] = [];

          errorMessage = err.error.message || err.message;

          if (typeof errorMessage === 'object') {
            for (const [key, value] of Object.entries(errorMessage)) {
              errors.push(key + ' : ' + value);
            }
          } else {
            errors.push(errorMessage);
          }

          this.errorService.serverErrors.next(errors);
          this.loadingService.stopLoading();
          this.loadingService.currentLoading.next(null);

          return [];
        }),
      );
  }
}
