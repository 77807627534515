import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from './api.service';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {PlatformService} from './platform.service';
import {User} from '../interfaces/user';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser = new BehaviorSubject<User>(null);
  public isAdmin = new BehaviorSubject<boolean>(false);
  public currentToken = new BehaviorSubject<string>(null);
  public lastRoute = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private platformService: PlatformService,
    private apiService: ApiService,
  ) {
  }

  getCurrentUser(): void {
    this.apiService.get('/auth/user')
      .subscribe(user => {
        if (user?.data) {
          this.currentUser.next(user.data);
          this.platformService.localStorageSet('currentCategory', user.data.category);
        }
      });
  }

  login(credentials: any): any {
    return this.http.post<any>(`${environment.apiEndPoint}/auth/login`, credentials, httpOptions)
      .pipe(map(result => {
        if (result?.data?.auth_token) {
          this.platformService.localStorageSet('currentToken', result.data.auth_token);
          this.currentToken.next(result.data.auth_token);
          this.getCurrentUser();
        }
        return result;
      }));
  }

  register(data: any): any {
    return this.apiService.post('/auth/register', data)
      .pipe(map(result => {
        if (result?.data?.auth_token) {
          this.platformService.localStorageSet('currentToken', result.data.auth_token);
          this.currentToken.next(result.token);
          this.autoLogin();
        }
        return result;
      }));
  }

  passwordResetRequest(data: any): any {
    return this.apiService.post('/auth/request-reset', data);
  }

  passwordResetConfirm(data: any): any {
    return this.apiService.post('/auth/confirm-reset', data);
  }

  logout(): void {
    this.platformService.localStorageRemove('currentToken');
    this.platformService.localStorageRemove('currentCategory');
    this.currentToken.next(null);
    this.currentUser.next(null);
    this.router.navigate(['']).then();
  }

  autoLogin(): void {
    if (this.platformService.localStorageGet('currentToken')) {
      this.getCurrentUser();
    }
  }
}
