import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {PlatformService} from '../../../core/services/platform.service';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currentYear: string;
  public agences: any[] = [];

  constructor(
    private apiService: ApiService,
    private platformService: PlatformService,
  ) {
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear().toString();
    if (this.platformService.isBrowser()){
      this.apiService.get('/agence')
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged(),
          debounceTime(300)
        )
        .subscribe(agences => this.agences = agences?.data?.filter(x => x.est_dispo));
    }
  }

}
