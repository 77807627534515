import { Injectable } from '@angular/core';
import {PlatformService} from './platform.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  public lastLawyerId = new BehaviorSubject<number | null>(null);

  constructor(
    private platformService: PlatformService,
  ) { }

  toTop(top: number): void {
    if (this.platformService.isBrowser()) {
      window.scrollTo(0, top);
    }
  }
}
