import { Injectable } from '@angular/core';
import {PlatformService} from './platform.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public showMenu = new BehaviorSubject<boolean>(false);

  constructor(
    private platformService: PlatformService,
  ) { }

  toggleMenu(): void {
    this.showMenu.next(!this.showMenu.value);
  }
}
